// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  API: '',
  offShoreAPI: 'https://us-central1-customer-service-portal-325207.cloudfunctions.net/',
  devShoreAPI: 'https://us-west2-deals-automation-development.cloudfunctions.net/',
  uatShoreAPI: 'https://us-west2-deals-automation-development.cloudfunctions.net/',
  prodShoreAPI: 'https://us-west2-deals-automation-production.cloudfunctions.net/',
  redirectURLSSO: 'https://sso.connect.pingidentity.com/sso/sp/initsso?saasid=489c5d8f-6cc5-4ce9-9848-ce184392e50a&idpid=new-item-billing.wl.r.appspot.com',
  offShoreInvoiceAPI: 'https://us-central1-customer-service-portal-325207.cloudfunctions.net/',
  devShoreInvoiceAPI: 'https://us-west2-deals-automation-development.cloudfunctions.net/',
  uatShoreInvoiceAPI: 'https://us-west2-deals-automation-development.cloudfunctions.net/',
  prodShoreInvoiceAPI: 'https://us-west2-deals-automation-production.cloudfunctions.net/',

  getLibraryValues: 'getLibraryValues',
  uploadInvoiceFiles: 'uploadInvoiceFiles',
  verifyInvoicingDealsLogin: 'verifyInvoicingDealsLogin',
  InvoiceUrl: [
    'profile/search/email', 'profile/search/phone', 'profile/update/account', 'profile/update/emailpreference',
    'profile/update/homestore', 'profile/guid/account/email/read', 'profile/guid/account/phone/read',
    'profile/update/phone', 'profile/update/email', 'ccpa/account/delete'
  ],
  redirectURLSSODev:'https://desktop.pingone.com/savemart/url?source=application&url=https%3A%2F%2Fsso.connect.pingidentity.com%2Fsso%2Fsp%2Finitsso%3Fsaasid%3Dd4c6830e-af0b-48a6-b846-d28ffc54d4a3%26idpid%3Dardealsinvoicing-dev.savemart.com&title=IDP%20Connection&applicationtype=APPLICATION_DEFAULT&saasid=d4c6830e-af0b-48a6-b846-d28ffc54d4a3&newDock=true',
  //redirectURLSSOProd:'https://desktop.pingone.com/savemart/url?source=application&url=https%3A%2F%2Fsso.connect.pingidentity.com%2Fsso%2Fsp%2Finitsso%3Fsaasid%3Dd4c6830e-af0b-48a6-b846-d28ffc54d4a3%26idpid%3Dardealsinvoicing.savemart.com&title=IDP%20Connection&applicationtype=APPLICATION_DEFAULT&saasid=d4c6830e-af0b-48a6-b846-d28ffc54d4a3&newDock=true',
  redirectURLSSOProd:'https://desktop.pingone.com/savemart/url?source=application&url=https%3A%2F%2Fsso.connect.pingidentity.com%2Fsso%2Fsp%2Finitsso%3Fsaasid%3Dbbc7a0be-1aec-4b45-b80f-6c17b9f9cb26%26idpid%3DARDealsInvoicing.savemart.com&title=IDP%20Connection&applicationtype=APPLICATION_DEFAULT&saasid=bbc7a0be-1aec-4b45-b80f-6c17b9f9cb26&newDock=true',
  authenticatePing:'AuthenticateWithPing',  
  AddUpdateUsers: 'AddUpdateUsers',
  uploadInvoiceMovementFiles: 'UploadInvoiceMovementFiles',
  generateExcel: 'generateExcel',
  AddUpdateCustomer: 'AddUpdateCustomer',
  AcceptInvoices: 'AcceptInvoices',
  sendEmail: 'generatePDF',
  updateInvoiceDetails: "updateInvoiceDetails",
  DeleteInvoices:"DeleteInvoices",
  downloadInvoice :"downloadInvoice",
  generatepdfcustomerbulkemail: "generatepdfcustomerbulkemail",
  updateBulkCustomertoInvoices: "updateBulkCustomertoInvoices",
  AddUpdatesExceptionComments:"AddUpdatesExceptionComments",
  RestoreInvoices:"RestoreArchiveInvoiceData"
};
