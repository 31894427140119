import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app.routing.module";
import { HttpServer } from "./services/http";
import { Util } from "./services/util";
import { PagerService } from "./services/pagerservice";
import { SharedModule } from "./services/shared.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { HttpClientModule } from "@angular/common/http";
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
  ],
  providers: [HttpServer, Util, PagerService],
  bootstrap: [AppComponent],
})
export class AppModule {}
